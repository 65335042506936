import React from 'react';

const sizes = {
  default: `py-3 px-8`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`
};

const Button = ({ children, className = '', size, props }) => {
  return (
    <button
      type="button"
      className={`
        ${sizes[size] || sizes.default}
        ${className}
        border border-black text-black bg-transparent hover:bg-black hover:text-white transition-colors duration-300
    `}
    >
      {children}
    </button>
  );
};

export default Button;
